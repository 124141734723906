<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-form @submit.prevent="formSubmit">
                    <b-card title="">

                        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                            <div class="alert-body">
                                {{error_message}}
                            </div>
                        </b-alert>

                        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
                            <b-col md=12>
                                <div class="demo-inline-spacing float-right "> 
                                    <b-button variant="danger" class="mt-0 mb-1" :to="{ name: 'locations-for-mpr'}">
                                        <feather-icon icon="MapIcon" class="mr-25"/>
                                        <span>Locations</span>
                                    </b-button>
                                    <!-- <b-button variant="danger" class="mt-0 mb-1" @click="openLocations()">
                                        <feather-icon icon="MapIcon" class="mr-25"/>
                                        <span>Locations</span>
                                    </b-button> -->

                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12">
                                <h4 class="card-title float-left"> Mosquito Prevention Report </h4>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col md="12">
                                <b-row>

                                    <b-col md="4">
                                        <b-form-group label="Project Site" class="required">
                                            <b-form-select v-model="form.site" @change="siteChange();" :disabled="disabledOptions">
                                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Property Division" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.property_division" :disabled="disabledOptions"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Submitted By" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.submitted_by" :disabled="disabledOptions"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Submitted Date" class="required">
                                            <b-form-datepicker v-model="form.submitted_date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="siteChange()" :disabled="disabledOptions"/>
                                        </b-form-group>
                                    </b-col>
                                    
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-card>

                    <!-- All Blocks -->
                    <b-card>
                        <b-row class="mb-0">
                            <b-col md="4">
                                <b-form-group label="Block No." class="required">
                                    <b-form-input autocomplete="off" placeholder="Enter Block No." v-model="form.block_no"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Date of Oiling and Fogging">
                                    <b-form-datepicker v-model="form.date_of_oiling_and_fogging" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- All Defect Type -->
                    <b-card>
                        <b-row class="mb-1 mt-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>
                            <b-col md="1">
                                <h5> Graphics </h5>
                            </b-col>
                            <b-col md="2">
                                <h5> Locations <hr>Block No : {{form.block_no}} </h5>
                            </b-col>
                            <b-col class="vertical-text" md="1">
                                <h5> Domestic Containers </h5>
                            </b-col>
                            <b-col class="vertical-text" md="1">
                                <h5> Discarded Receptacles </h5>
                            </b-col>
                            <b-col class="vertical-text" md="1">
                                <h5> Silted / Obstructed With Dried Leaves </h5>
                            </b-col>
                            <b-col class="vertical-text" md="1">
                                <h5> Unlevelled / Sunken Floor / Broken Base </h5>
                            </b-col>
                            <b-col class="vertical-text" md="1">
                                <h5> Thick Axils / Holes </h5>
                            </b-col>
                            <b-col style="vertical-align: middle;" md="3">
                                <h5> Remarks </h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-for="(mpr, ind) in form.mosquito_prevention_report" :key="ind">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{ind + 1}} </b-col>
                                <b-col md="1" class="mb-1">
                                    <img class="rounded img-fluid" :src="mpr.graphics">
                                </b-col>
                                <b-col md="2" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="mpr.location_name"/>
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <!-- <b-form-input autocomplete="off" :disabled="ind === 1" placeholder="" v-model="mpr.domestic_containers"/> -->
                                    
                                    <b-form-input autocomplete="off" v-if="ind != 1" placeholder="" v-model="mpr.domestic_containers"/>

                                    
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="mpr.discarded_receptacles"/>
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="mpr.silted_obstructed_with_dried_leaves"/>
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="mpr.unlevelled_sunken_floor_broken_base"/>
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <!-- <b-form-input autocomplete="off" :disabled="ind != 0" placeholder="" v-model="mpr.thick_axils_holes"/> -->
                                    <b-form-input autocomplete="off" v-if="ind == 0" placeholder="" v-model="mpr.thick_axils_holes"/>
                                </b-col>
                                <b-col md="3" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="mpr.remarks"/>
                                </b-col>
                            </b-row>
                            <hr v-if="form.mosquito_prevention_report.length != (ind + 1)">
                        </div>
                    </b-card>

                    <!-- supervisor signature -->
                    <b-card>

                        <b-row>
                            <b-col cols="12">
                                <h4 class="text-center pb-2">Signature <span style="color:red">*</span></h4>
                                <center>
                                    <b-media class="" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">                                      
                                        <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" rounded style="height: 200px; width: auto"/>

                                        <div class="d-flex flex-wrap">
                                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                                        </div>
                                    </b-media>
                                    <img :src="supervisorSign.image" v-if="supervisorSign.image != null" class="rounded" height="180" width="200px" style="background: #ffffff;">
                                </center>
                                
                                <center>
                                    <b-button variant="danger" class="mt-1 ml-1" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions"> 
                                        <feather-icon icon="XIcon" />
                                    </b-button>
                                </center>
                            </b-col>
                            
                        </b-row>
                    
                        <b-row class="mt-2">
                            <b-col>

                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class = "mr-1" @click="goBack()">
                                    Cancel
                                </b-button>
                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(form.id)" v-if="form.id != null && typeof $route.params.id == 'undefined'">
                                    Discard Draft
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="form.status = 'draft'" :disabled="form.site == ''" v-if="typeof $route.params.id == 'undefined'">
                                    {{ form.id == null ? 'Save as Draft' : 'Update Draft'}}
                                </b-button>

                                <b-button 
                                    v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" 
                                    type = "submit" 
                                    variant = "success" 
                                    class = "mr-1" 
                                    @click="form.status = 'completed'"
                                >
                                    {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
                                </b-button>

                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    class="mr-1"
                                    v-if="typeof $route.params.id != 'undefined' && ledit == false"
                                    @click="openSignature('approver')"
                                >
                                    {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
                                </b-button>

                            </b-col>
                        </b-row>
                    </b-card>

                </b-form>

            </b-col>
        </b-row>

        <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
            ></date-picker>
          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
			<b-col md="12">
				<b-form-group
					label="Remarks"
					class=""
				>
					<b-form-textarea
					placeholder=""
					rows="3"
					v-model="approve_remark"
					/>
				</b-form-group>
			</b-col>
		  </b-row>

        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>

          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="3"  v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    </div>
</template>
<script>
    import {
        BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BFormDatepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive';
    import { GET_API, POST_API } from "../../../store/actions.type";
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import vSelect from 'vue-select';
    import Bus from "../../../event-bus";
    import Datepicker from 'vuejs-datepicker';
    var moment = require('moment-timezone');
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        components: {
            BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile, BFormDatepicker,BBreadcrumb, DatePicker
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,
                draftData:null,
                sites : null,

                form :{
                    id                         : null,
                    site                       : '',
                    property_division          : null,
                    submitted_by               : this.$store.getters.currentUser.full_name,
                    submitted_date             : moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
                    block_no                   : null,
                    date_of_oiling_and_fogging : '',
                    mosquito_prevention_report : [],
                    status                     : 'completed',
                },

                // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                supervisorSign : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
                openedSignModel:null,

                flag: false,
                tempSign : {
                    image:null,
                    name:'',
                },
                show_default:false,
                having_default:false,

                // new vars 
                disabledOptions:false,
                approve_signature_time:null,
                approve_remark:'',
                ledit : false,
                signature:{image:null,name:'',default:'no'},
                crew_signature : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
            }
        },

        methods: {
            formSubmit(){
                if (this.form.status == 'completed') {
                    this.decisionAlert('Are you sure want to submit this report ?')
                    .then(result => {
                      if (result.value) {
                        this.actualForm();            
                      }
                    })
                }else{
                    this.actualForm();
                }
            },
            actualForm(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                        supervisorSign : this.supervisorSign,
                        form_id : this.$route.params.id,
                        approve_signature : this.crew_signature,
                        approve_signature_time : this.approve_signature_time,
                        approve_remark: this.approve_remark,
                        role:this.$store.getters.currentUser.role,
                    },
                    api : '/api/add-mosquito-prevention-report'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        //this.showDismissibleAlert = true;

                        //window.scrollTo(0,0);
                        this.errorAlertCF()
                    } else {
                        this.showDismissibleAlert = false;

                        var data = this.$store.getters.getResults.data;

                        this.successAlert().then((result) => {

                            if (this.sites && this.sites.length == 1) {
                            
                                this.form.site = this.sites[0]._id;
                                this.siteChange();
                            }else{
                                this.form.site = '';
                                this.siteChange();
                                this.refreshData();
                            }
                            
                            

                            if(this.form.status == 'completed'){
                                // this.getLocations();
                            }
                            if (data != null) {
                                window.open(data, '_blank');
                            }

                            localStorage.setItem('routeCFCheck','no')

                            Bus.$emit('counter_update');

                            if(this.$route.params.id){
                                this.$router.push({name:'mosquito-prevention-report-pdf'})
                            }else{
                                this.$router.push({ name:'custom-forms' })
                            }
                        });
                    }
                });
            },
            
            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;

                        if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                            
                            this.form.site = this.sites[0]._id;
                            this.siteChange();
                            this.getLocations()
                        }

                        return this.sites;
                    }
                });
            },

            supervisorImageUpload(event){
                var input = event.target;
                var files = event.target.files

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    var image = input.files[0];

                    if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Please upload .jpg, .jpeg, .jpg images only',
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.$refs.supervisorSInput.value=null;

                    } else if (image.size > 2097152) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Maximum 2 MB files allowed to be upload.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$refs.supervisorSInput.value=null;
                    } else {
                        reader.onload = (e) => {
                            this.supervisorSign.image = e.target.result;
                            this.supervisorSign.name = image.name;
                            this.supervisorSign.type =  'supervisor';
                            this.supervisorSign.default = 'no';
                        }
                    }
                }
            },

            

            removeSign(sign){
                this.supervisorSign.image = null;
                this.supervisorSign.name = '';
                this.supervisorSign.default = 'no';
            },

            siteChange(){     

                this.form.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');           
                this.getdraftData()
                .then(() => {
                    if (this.draftData == null) {
                        this.refreshData();
                        this.getLocations();
                    }else{
                        this.form.id = this.draftData.id;
                        this.form.site = this.draftData.site;
                        this.form.submitted_by = this.draftData.submitted_by;
                        this.form.submitted_date = this.draftData.submitted_date;
                        this.form.status = this.draftData.status;
                        this.form.property_division = this.draftData.property_division;
                        this.form.submitted_date = this.draftData.submitted_date;
                        this.form.block_no = this.draftData.block_no;
                        this.form.date_of_oiling_and_fogging = this.draftData.date_of_oiling_and_fogging;
                        this.form.mosquito_prevention_report = this.draftData.mosquito_prevention_report;

                        if(this.draftData && this.draftData.crew_signature.image){
                            this.supervisorSign = this.draftData.crew_signature;
                        } else {
                            this.supervisorSign = {
                                image : this.tempSign.image,
                                name : 'signature.png',
                                type :   'supervisor',
                                default:'yes'
                            }
                        }
                        
                    } 
                });
            },

            getdraftData(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.form.site != '' ? this.form.site : null,
                        submitted_date:this.form.submitted_date
                    },
                    api: '/api/draft-mosquito-prevention-report-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        this.draftData = data;
                        
                        return this.draftData;
                    }
                });
            },

            discardItem(id){
                var msg = 'Are you sure want to discard this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-status-mosquito-prevention-report-data",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert()
                                .then(() => {
                                    this.flag = true;
                                    this.refreshData();
                                    localStorage.setItem('routeCFCheck','no')
                                    // this.$router.go(-1);
                                    this.$router.push({ name:'custom-forms' })
                                    // this.getLocations();
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            refreshData(){
                /*if(this.flag){
                    
                }*/

                //this.form.site = '';
                this.flag = false;
                this.form.id = null;

                this.form.submitted_by = this.$store.getters.currentUser.full_name;
                this.form.submitted_date = /* moment().tz('Asia/Singapore').format('YYYY-MM-DD') */ this.form.submitted_date;
                this.form.property_division = null;
                this.form.block_no = null;
                this.form.date_of_oiling_and_fogging = '';
                this.form.mosquito_prevention_report = [];

                this.supervisorSign = {
                    image:this.tempSign.image,
                    name:'',
                    type: 'supervisor',
                    default:'yes'
                };
                
                this.openedSignModel=null;
            },
            
            getReportJson(){
                return [
                    {
                        s_no: 1,
                        graphics: this.doc_icon,
                        locations: 'Open areas / landscape /bushes /tree trunks/roots',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 2,
                        graphics: this.doc_icon,
                        locations: 'Roof-scupper / water discharged outlet',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 3,
                        graphics: this.doc_icon,
                        locations: 'Common corridor - scupper/ water discharged outlet',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 4,
                        graphics: this.doc_icon,
                        locations: 'Void deck floor / gully trap',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 5,
                        graphics: this.doc_icon,
                        locations: 'Apron / drain car park drain Concealed / surfaced',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 6,
                        graphics: this.doc_icon,
                        locations: 'Stop cock pits / valve chambers',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 7,
                        graphics: this.doc_icon,
                        locations: 'MSCP-water discharged outlet / floor',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 8,
                        graphics: this.doc_icon,
                        locations: 'Bin compound - Gully / floor / unwanted articles',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }, {
                        s_no: 9,
                        graphics: this.doc_icon,
                        locations: 'Litter Bins - standing / hanging',
                        domestic_containers: '',
                        discarded_receptacles: '',
                        silted_obstructed_with_dried_leaves: '',
                        unlevelled_sunken_floor_broken_base: '',
                        thick_axils_holes: '',
                        remarks: ''
                    }
                ]
            },

            getLocations(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        // site:this.form.site
                    },
                    api: '/api/all-locations-for-mpr'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        if(this.form.mosquito_prevention_report.length < 1){
                            this.form.mosquito_prevention_report = this.form.site ? data : [];
                        }
                        
                    }
                });
            },
            useDefault(){

              return this.$store.dispatch(POST_API, {
                   data:{
                     id:this.$store.getters.currentUser._id
                   },
                   api: '/api/get-default-signature'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data == null || data.default_signature == null) {
                          
                          this.having_default = false;
                          
                        }else{

                          this.tempSign = {
                            image : data.default_signature,
                            name : 'siganture.png',
                          }

                            this.supervisorSign = {
                                image:data.default_signature,
                                name:'signature.png',
                                type: 'supervisor',
                                default:'yes'
                            };

                          this.having_default = true;
                        }
                    }
                });
            },
            uploadSign(){
              
                this.$refs['supervisorSInput'].click();
              
                this.$refs['signatureModel'].hide();

            },
            openSignature(type){
              this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');

              if (this.openedSignModel == null) {

                  if (this.having_default == true) {
                      this.show_default = true;
                  }else{
                    this.show_default = false;
                  }

                  this.$refs['signatureModel'].show();
                  this.openedSignModel = type;
              }
            },
            hideSignature(){
              this.openedSignModel = null;
            },
            clearSignature(){
              this.$refs.signaturePad.clearSignature();
            },
            saveSignature(){
              const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
              
              if (isEmpty) {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
                })
              } else {
                if (this.openedSignModel == 'supervisor') {
                    this.supervisorSign.image = data;
                    this.supervisorSign.name = 'signature.png';
                    this.supervisorSign.type = 'supervisor';
                    this.supervisorSign.default = 'no';
                } else {
                    this.crew_signature.image = data;
                    this.crew_signature.name = 'signature.png';
                    this.crew_signature.type = 'supervisor';
                    this.crew_signature.default = 'no';
                    this.form.status = 'approved';
                    this.actualForm();
                }
                this.$refs['signatureModel'].hide();
              }
            },
            saveDefault(){
                if (this.openedSignModel == 'supervisor') {
                    this.supervisorSign = {
                        image : this.tempSign.image,
                        name : 'signature.png',
                        default:'yes'
                    }
                }else{
                    this.crew_signature = {
                        image : this.tempSign.image,
                        name : 'signature.png',
                        default:'yes',
                        type:'supervisor'
                    }
                    this.form.status = 'approved';
                    this.actualForm();
                }      
                this.$refs['signatureModel'].hide();
            },
            breadCrumb(){
                if(this.$route.params.id){
                    var item = [{
                        to:{name:'client-dashboard'},
                        text: 'Dashboard',
                    },{
                        to:null,
                        text: 'Reports',
                    },{
                      to:{name:'custom-forms-dashboard'},
                      text: 'Form Dashboard',
                    },{
                        to:{name:'custom-forms-reports'},
                        text:'Custom Forms'
                    },{
                        to:{name:'mosquito-prevention-report-pdf'},
                        text:'Mosquito Prevention Report'
                    },{
                        to:null,
                        text:'Edit',
                        active:true
                    }];
                    return this.filterReportBreadCrum(item);
                } else {
                    var item = [{
                        to:{name:'client-dashboard'},
                        text: 'Dashboard',
                    },{
                        to:{name:'custom-forms'},
                        text: 'Custom Forms',
                    },{
                        to:null,
                        text:'Mosquito Prevention Report',
                        active:true
                    }];
                }

              return item;
            },
            openLocations(){
              this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.form.site != '' ? this.form.site : 'all-site',id:'all-building',form:'Mosquito_Prevention_Report'}})
            },

            goBack(){

                if (this.form.site != '') {
                    var msg = 'Do you want to Go back without saving your report?';

                    this.decisionAlert(msg)
                    .then(result => {
                      if (result.value) {
                        localStorage.setItem('routeCFCheck','no');                        
                        // this.$router.go(-1);
                        if(this.$route.params.id){
                           this.$router.push({name:'mosquito-prevention-report-pdf'})
                        }else{
                            this.$router.push({ name:'custom-forms' })
                        }

                      }
                    })
                }else{
                    // this.$router.go(-1);  
                    this.$router.push({ name:'custom-forms' })                  
                }
                
            },

            disabledDate(date){
                return date < moment(new Date(this.form.submitted_date)).subtract(1,'days').tz('Asia/Singapore');
            },

            checkdisabled(){
                if (this.ledit == true) {
                    return false;
                } else if (this.disabledOptions == true){
                    return true;
                }else{
                    return false;
                }
            },

            getFormDetails(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.$route.params.id
                    },
                    api: '/api/get-mpr-report-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        this.form.id = data._id;
                        this.form.site = data.site;
                        this.form.submitted_by = data.submitted_by;
                        this.form.submitted_date = data.submitted_date;
                        this.form.status = data.status;
                        this.form.property_division = data.property_division;
                        this.form.submitted_date = data.submitted_date;
                        this.form.block_no = data.block_no;
                        this.form.date_of_oiling_and_fogging = data.date_of_oiling_and_fogging;
                        this.form.mosquito_prevention_report = data.mosquito_prevention_report;

                        if(data && data.signature){
                            this.supervisorSign = {
                                image   : data.signature,
                                name    : 'signature.png',
                                type    : 'supervisor',
                                default : 'yes'
                            }
                        } else {
                            this.supervisorSign = {
                                image   : this.tempSign.image,
                                name    : 'signature.png',
                                type    : 'supervisor',
                                default : 'yes'
                            }
                        }

                        var role = this.$store.getters.currentUser.role;

                        if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                            //this.disabledOptions = false;
                            this.ledit = true;
                        } else if(((role == 'administrator') || (role == 'admin')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                            this.ledit = true;
                        }
                    }
                });

            },
        },
        mounted(){

            this.siteChange();
            this.useDefault();
            this.allSites();
            
            if(this.$route.params.id){
                this.disabledOptions = true;
                this.getFormDetails();
            }
        }
    }
</script>
<style scoped>
    /* .vertical-text {
        transform: rotate(180deg);
        writing-mode: vertical-lr;
    } */
</style>
